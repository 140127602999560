import { useState } from 'react';
import { Formik } from 'formik';
import MessageComponent from './message';
import * as Yup from 'yup';
import InputField from '../home/inputField';
import logger from '@utils/logger';

function ContactForm({ children }) {
	const [response, setResponse] = useState('');
	const [isSending, setIsSending] = useState(false);

	return (
		<section className="margin-right">
			<Formik
				initialValues={{ email: '', name: '', body: '' }}
				onSubmit={async (values, { resetForm }) => {
					setIsSending(true);
					// Process a POST request
					const result = await fetch('/api/mailer', {
						method: 'POST', // or 'PUT'
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(values)
					})
						.then((response) => {
							if (!response.ok) {
								throw Error(response.statusText);
							}
							return response.json();
						})
						.then((data) => {
							logger.log('Email was sent');
							setResponse({
								title: 'Success',
								body: 'Email was sent! I will get back to you asap :)',
								type: 'success'
							});
						})
						.catch((error) => {
							logger.error('Email was not sent');
							setResponse({
								title: 'Error',
								body: 'Oups! Something went wrong.. please try later',
								type: 'danger'
							});
						});
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string().email().required('Please Enter your Email'),
					name: Yup.string().required('Please enter your name'),
					body: Yup.string()
						.required('Please write your message here')
						.min(30, 'You message should be at least 30 letters')
				})}>
				{(props) => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset
					} = props;
					return (
						<form onSubmit={handleSubmit}>
							<InputField
								name="name"
								id="name"
								type="input"
								placeholder="Your Name"
								icon="fas fa-user"
								classes="is-medium"
								error={errors.name && touched.name ? errors.name : ''}
								classes={errors.name && touched.name ? 'is-danger' : ''}
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={!!response}
							/>

							<InputField
								name="email"
								type="email"
								placeholder="Your Email"
								icon="fas fa-envelope"
								classes="is-medium"
								error={errors.email && touched.email ? errors.email : ''}
								classes={errors.email && touched.email ? 'is-danger' : ''}
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={!!response}
							/>

							<InputField
								name="body"
								id="body"
								label="Message"
								type="textarea"
								placeholder="Your Message"
								classes="is-medium"
								error={errors.body && touched.body ? errors.body : ''}
								classes={errors.body && touched.body ? 'is-danger' : ''}
								value={values.body}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={!!response}
							/>
							{/* is-dark is-fullwidth has-text-weight-medium is-medium */}
							<div className="control">
								{!response ? (
									<button
										type="submit"
										className="button is-dark is-fullwidth has-text-weight-medium is-medium"
										disabled={isSending}>
										Send Message &nbsp;
										{isSending && <i className="fa fa-spinner fa-spin" />}
									</button>
								) : (
									<MessageComponent
										handleClose={(e) => {
											setResponse('');
											setIsSending(false);
										}}
										{...response}
									/>
								)}
							</div>
						</form>
					);
				}}
			</Formik>
		</section>
	);
}

export default ContactForm;
