import classNames from 'classnames';

const Socials = ({ list, isBlack, isMedium, showOnly = [] }) => {
	const showOnlyLowerCase = showOnly.map((x) => x.toLowerCase());
	let filtredList = list;
	if (showOnly.length > 0) {
		filtredList = list.filter(({ name }) => showOnlyLowerCase.includes(name.toLowerCase()));
	}

	return (
		<div
			className={classNames('social-media center box is-shadowless no-margin-bottom', {
				'has-background-black': isBlack
			})}>
			{filtredList.map(({ id, link, icon, name, username }, index) => (
				<a
					key={id}
					href={link}
					target="_blank"
					className={classNames('button is-transparent', {
						'is-large': !isMedium,
						'is-medium': isMedium
					})}>
					<i className={`fab fa-${icon}`} aria-hidden="true" />
				</a>
			))}
		</div>
	);
};

export default Socials;
